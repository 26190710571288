import $ from 'jquery';

var adjustStickyHeader = function() {
    var height = $('header').outerHeight();
    $('.main-container').css('top', height + 'px');
    console.log('new height: ', height + 'px');
};

$(function() {
    adjustStickyHeader();
    $(window).on('resize', adjustStickyHeader);
});

export default adjustStickyHeader;